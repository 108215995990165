<template>
    <div>
        <ul class="list-unstyled">
            <dl class="row mx-0 my-0 px-0">
                <dt class="col-sm-6 px-0">{{ fields.tenant_name.label }}:</dt>
                <dd class="col-sm-6 px-0">{{ model.tenant_name }}</dd>
            </dl>
            <dl class="row mx-0 my-0 px-0">
                <dt class="col-sm-6 px-0">{{ fields.layer_name.label }}:</dt>
                <dd class="col-sm-6 px-0">{{ model.layer_name  }}</dd>
            </dl>
            <dl class="row mx-0 my-0 px-0">
                <dt class="col-sm-6 px-0">{{ fields.sublayer_name.label }}:</dt>
                <dd class="col-sm-6 px-0">{{ model.sublayer_name  }}</dd>
            </dl>
            <dl class="row mx-0 my-0 px-0">
                <dt class="col-sm-6 px-0">{{ fields.gis_attribute_name.label }}:</dt>
                <dd class="col-sm-6 px-0">{{ model.gis_attribute_name ?? 'N/A' }}</dd>
            </dl>
            <dl class="row mx-0 my-0 px-0">
                <dt class="col-sm-6 px-0">{{ fields.metadata_name.label }}:</dt>
                <dd class="col-sm-6 px-0">{{ model.metadata_name }}</dd>
            </dl>
            <dl class="row mx-0 my-0 px-0">
                <dt class="col-sm-6 px-0">{{ fields.intersection_type.label }}:</dt>
                <dd class="col-sm-6 px-0">{{ intersectionType }}</dd>
            </dl>
            <dl class="row mx-0 my-0 px-0">
                <dt class="col-sm-6 px-0">{{ fields.metadata_completion_method.label }}:</dt>
                <dd class="col-sm-6 px-0">{{ completionMethod }}</dd>
            </dl>
            <dl class="row mx-0 my-0 px-0">
                <dt class="col-sm-6 px-0">{{ fields.gis_attribute_value_override.label }}:</dt>
                <dd class="col-sm-6 px-0">{{ model.gis_attribute_value_override ?? 'N/A' }}</dd>
            </dl>
        </ul>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import { FormSchema } from "@/shared/form/form-schema";
import { GisIntersectionsStageSettingsModel } from "@/modules/process-editor/models/gis-intersections/gis-intersections-stage-settings-model";

const { fields } = GisIntersectionsStageSettingsModel;

const formSchema = new FormSchema([
    fields.id,
    fields.tenant_id,
    fields.tenant_name,
    fields.layer_id,
    fields.layer_name,
    fields.sublayer_id,
    fields.sublayer_name,
    fields.gis_attribute_id,
    fields.gis_attribute_name,
    fields.metadata_name,
    fields.intersection_type,
    fields.metadata_completion_method,
    fields.gis_attribute_value_override,
]);

export default {
    name: "GisIntersectionsStageSettingsViewForm",
    data() {
        return {
            fields,
        };
    },
    computed: {
        ...mapGetters({
            record: 'processEditor/gisIntersectionSettings/record',
        }),
        gisValueLabel() {
            return this.$t('PROCESS_EDITOR.STEPS.GIS_INTERSECTIONS.GIS_VALUE_LABEL');
        },
        gisValueLabelExtended() {
            return this.$t('PROCESS_EDITOR.STEPS.GIS_INTERSECTIONS.GIS_VALUE_LABEL_EXTENDED');
        },
        intersectionType() {
            return fields.intersection_type?.options?.find(v => v.value === this.model.intersection_type)?.text;
        },
        completionMethod() {
            return fields.metadata_completion_method?.options?.find(v => v.value === this.model.metadata_completion_method)?.text;
        },
    },
    async created() {
        this.model = formSchema.initialValues(this.record);
    },
};
</script>
